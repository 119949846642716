import moment from 'moment'

export const reactSelectValue = (opts, id, key = 'value') =>
  opts.find((o) => o[key] === id) || null
export const reactSelectMultiValue = (opts, ids, key = 'value') => {
  let values = []

  ;(ids || []).map((id) => {
    let opt = opts.find((o) => o[key] === id)
    if (!opt) opt = { value: id, label: id }

    values.push(opt)
  })

  return values
}

export function mySqlDate(dateString, mode = 'date') {
  if (!dateString) {
    return ''
  }

  return moment(dateString).format(
    'YYYY-MM-DD' + (mode == 'time' ? ' HH:mm:ss' : '')
  )
}

export function getTimePassed(string, locale = 'th') {
  moment.locale(locale)

  return moment(string).fromNow()
}

export function getPermissions() {
  let user = localStorage.getItem('user')

  if (!user) {
    return []
  }

  user = JSON.parse(user)

  return user.role.permissions || []
}

export function can(permission) {
  return getPermissions().indexOf(permission) > -1
}

export function forceBranch(type) {
  let user = localStorage.getItem('user')
  if (!user) {
    return null
  }

  user = JSON.parse(user)

  return !user.branch_id || can(type + '_ab') ? null : user.branch_id
}

export function inputKeyPressOnlyNumber(event) {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault()
  }
}

export function inputKeyPressOnlyNumberAndDot(event) {
  if (!/[0-9]/.test(event.key) && !event.key == '.') {
    event.preventDefault()
  }
}

export function inputKeyPressOnlyNumberAndMinus(event) {
  if (!/[0-9]/.test(event.key) && event.key !== '-') {
    event.preventDefault()
  }
}

export function inputKeyPressOnlyNumberAndMinusWithDot(event) {
  if (!/[0-9]/.test(event.key) && event.key !== '-' && !event.key == '.') {
    event.preventDefault()
  }
}

export const getAge = (date) => {
  if (!date) return '-'

  const months = moment().diff(moment(date), 'months')
  const years = Math.floor(months / 12)
  const partialMonth = months % 12
  const monthText = partialMonth > 0 ? ` ${partialMonth} เดือน` : ''
  const yearsText = `${years} ปี`
  let dayText = ' '

  if (moment().date() > moment(date).date()) {
    dayText += moment().date() - moment(date).date() + ' วัน'
  } else if (moment().date() < moment(date).date()) {
    dayText +=
      moment().diff(
        moment().subtract(1, 'month').date(moment(date).date()),
        'days'
      ) + ' วัน'
  } else {
    dayText = ''
  }

  return yearsText + monthText + dayText
}

export function getBMIText(h, w) {
  const _h = Number(h || 0) || 0
  const _w = Number(w || 0) || 0

  if (_h === 0 || _w === 0) {
    return 0
  }

  const _hm = _h > 0 ? h / 100 : 0
  return _w > 0 ? _w / (_hm * _hm) : 0
}

export const inputOnlyNumber = (e) => {
  if (!(/^[0-9]$/i.test(e.key) || e.metaKey || e.which <= 0 || e.which == 8)) {
    e.preventDefault()
  }
}

export const inputOnlyPrice = (e) => {
  if (
    !(
      /^[0-9]$/i.test(e.key) ||
      e.key === '.' ||
      e.metaKey ||
      e.which <= 0 ||
      e.which == 8
    )
  ) {
    e.preventDefault()
  }
}

export const getProductDotColor = (expiredAt) => {
  const currentDate = moment()
  const expiryDate = moment(expiredAt)
  const diffMonths = expiryDate.diff(currentDate, 'months')
  if (diffMonths <= 1) return 'red'
  if (diffMonths > 1 && diffMonths <= 3) return 'darkorange'
  if (diffMonths > 3 && diffMonths <= 6) return 'peachpuff'
  if (diffMonths > 6 && diffMonths <= 12) return 'lightgreen'
  if (diffMonths > 12 && diffMonths <= 18) return 'green'
  if (diffMonths > 18 && diffMonths <= 24) return 'black'
  return 'black'
}

export const getDisplayProductId = (productId) => {
  const deletedAtIndex = productId.indexOf('(deleted@')
  if (deletedAtIndex !== -1) {
    return productId.substring(0, deletedAtIndex)
  } else {
    return productId
  }
}

export const calculateSmallestUnitQuantity = (
  unit,
  subUnitLevel1,
  subUnitLevel2,
  unitName,
  quantity
) => {
  if (unit && unit.name === unitName) {
    if (subUnitLevel2) {
      return (
        subUnitLevel2.quantity_per_unit *
        subUnitLevel1.quantity_per_unit *
        quantity
      )
    } else if (subUnitLevel1) {
      return subUnitLevel1.quantity_per_unit * quantity
    } else {
      return quantity
    }
  } else if (subUnitLevel1 && subUnitLevel1.name === unitName) {
    if (subUnitLevel2) {
      return subUnitLevel2.quantity_per_unit * quantity
    } else {
      return quantity
    }
  } else if (subUnitLevel2 && subUnitLevel2.name === unitName) {
    return quantity
  }
  return quantity
}

export const convertQuantityFromSelectedUnit = (
  unit,
  subUnitLevel1,
  subUnitLevel2,
  smallestQuantity,
  selectedUnit
) => {
  // If not yet selected the unit, set it as 0.
  if (!selectedUnit) {
    return 0
  }
  if (unit && selectedUnit === unit.name) {
    if (subUnitLevel2) {
      return (
        smallestQuantity /
        (subUnitLevel2.quantity_per_unit * subUnitLevel1.quantity_per_unit)
      )
    } else if (subUnitLevel1) {
      return smallestQuantity / subUnitLevel1.quantity_per_unit
    } else {
      return smallestQuantity
    }
  } else if (subUnitLevel1 && selectedUnit === subUnitLevel1.name) {
    if (subUnitLevel2) {
      return smallestQuantity / subUnitLevel2.quantity_per_unit
    } else {
      return smallestQuantity
    }
  } else {
    return smallestQuantity
  }
}

export const objectToQueryString = (obj) => {
  return Object.entries(obj)
    .flatMap(([key, value]) =>
      Array.isArray(value)
        ? value.map(
            (v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`
          )
        : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&')
}

export const formatNumber = (value) => {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
